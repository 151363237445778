<template >
    <div class="logList">
        <div class="logButton">
            <div class="export">
                <el-button type="primary" @click="dialogVisible = true">添加成员</el-button>
            </div>
            <div class="search">
                <!-- 搜索 -->
                <div class="searchContent">
                    <el-input placeholder="请输入成员姓名或工号" v-model="searchContent" class="input-with-select" ></el-input>
                </div> 
                <el-button type="primary" @click="handleSearchColleague">搜索</el-button>
            </div>
        </div>
        <!-- 列表 -->
        <el-card>
            <el-table
                ref="multipleTable"
                :data="list"
                tooltip-effect="dark"
                style="width: 100%">
                    <!-- 序号 -->
                    <el-table-column
                        type="index"
                        label="序号"
                        width="100">
                    </el-table-column>
                    <!-- 头像 -->
                    <el-table-column
                        label="头像"
                        width="200">
                        <template slot-scope="scope">
                            <img class="wx_img" :src="scope.row.wx_img">
                        </template>
                    </el-table-column>
                    <!--姓名  -->
                    <el-table-column
                        prop="name"
                        label="用户名">
                    </el-table-column>
                    <!--手机号 -->
                    <el-table-column
                        prop="personal_id"
                        label="教职工号">
                    </el-table-column>
                    <!-- 状态 -->
                    <el-table-column
                        label="状态"
                        width="200">
                        <template slot-scope="scope">
                            <span v-if="scope.row.status == 1">已同意</span>
                            <span v-else>未同意</span>
                        </template>
                    </el-table-column>
                    <!-- 操作-->
                    <el-table-column
                    label="操作">
                        <template slot-scope="scope">
                            <el-button type="primary" @click="deleteMember(scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-pagination
                :current-page.sync="currentPage"
                background
                :page-size="pageSize"
                layout="prev, pager, next"
                :total="listTotal">
            </el-pagination>
        </el-card>
            <el-dialog
            title="添加成员"
            :visible.sync="dialogVisible"
            width="30%">
                <span  class="dialog-span">
                    <el-select
                        v-model="current_id"
                        filterable
                        remote
                        clearable
                        placeholder="请输入成员姓名或工号!"
                        :remote-method="searchMember">
                        <el-option
                            v-for="item in searchList"
                            :key="item.id"
                            :label="item.str"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </span>
            <span
                slot="footer"
                class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="confirmAdd">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    // 引入试题列表js
    import logList from "@/assets/js/teacher/center/memberList.js";
    export default {
        ...logList
    }
</script>

<style lang="scss" scoped>
// 引入试题列表组件css
    @import "@/assets/css/teacher/center/memberList.scss";
</style>