import { Message } from "element-ui"; //项目已经全局引入element的话可以不单独引入
import router from "@/router";
import { startLoading, endLoading } from "@/utils/function";
import API from '@/api/teacher/center/memberList.js';
import { nprogressStart, nprogressClose } from "@/plugins/nprogress.js";
export default {
        // 页面名称
        name: "colleague",
        data()
        {
            return{
                list:[],
                listTotal:0,
                searchContent:"",
                searchList:[],
                currentPage:1,
                pageSize:7,
                current_id:'',
                dialogVisible : false,
            }
        },
        created()
        {
            this.getData()
        },
        watch:
        {
            currentPage(newVal,oldVal)
            {
                this.getData();
            }
        },
        methods:
        {
            getData()
            {
                // try 
                // {
                    let self = this;
                    // 请求参数
                    let data = {
                        search : self.searchContent,
                        page : self.currentPage,
                        pageSize: self.pageSize
                    };
                    nprogressStart();
                    self.$Loading.show();
                    // 获取同事列表请求
                    API.showCenterList(data)
                        .then((result)=>{
                            nprogressClose();
                            self.$Loading.hide();
                            if(result.code > 0 )
                            {
                                self.list = result.data
                                self.listTotal = result.total;
                            }
                        })                
                // } 
                // catch (error) 
                // {
                //     Message.error('系统错误') 
                // }
            },
            deleteMember(e)
            {
                try 
                {
                    let self = this;
                    // 请求参数
                    let data = {
                        id : e.id
                    };
                    // 删除同事请求
                    self.$confirm('确认要删除该成员吗?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        API.deleteMember(data)
                        .then((result)=>{
                            if(result.code > 0 )
                            {
                                self.getData();
                                this.$Tips({
                                    // 消息提示内容
                                    message:result.msg,
                                    // 消息提示类型（success-成功,warning-警告/失败）
                                    messageType:'success',
                                    displayTime:1500
                                })
                            }
                        })  
                    }).catch(() => {
                        Message.info('已取消');        
                    });
                } 
                catch (error) 
                {
                    Message.error('系统错误');
                }
            },
            confirmAdd()
            {
                try 
                {
                    let self=this;
                    // 请求参数
                    let data = {
                        current_id : self.current_id
                    };
                    // 添加同事请求
                    API.addMember(data)
                        .then((result)=>{
                            if(result.code > 0 )
                            {
                                self.dialogVisible = false;
                                self.current_id = '';
                                // Message.success('添加成功');
                                self.getData();
                                this.$Tips({
                                    // 消息提示内容
                                    message:result.msg,
                                    // 消息提示类型（success-成功,warning-警告/失败）
                                    messageType:'success',
                                    displayTime:1500
                                })
                            }
                            else
                            {
                                // Message.error('系统错误')
                                this.$Tips({
                                    // 消息提示内容
                                    message:result.msg,
                                    // 消息提示类型（success-成功,warning-警告/失败）
                                    messageType:'warning',
                                    displayTime:1500
                                })
                            }
                        })                
                } 
                catch (error) 
                {
                    Message.error('系统错误');
                }
            },
            searchMember(e)
            {
                try 
                {
                    let self = this;
                    let data = {
                        search: e
                    }
                    if(e)
                    {
                        API.searchMember(data)
                        .then((result)=>{
                            if(result.code > 0 )
                            { 
                                self.searchList = result.data
                            }
                            else
                            {
                                this.$Tips({
                                    // 消息提示内容
                                    message:result.msg,
                                    // 消息提示类型（success-成功,warning-警告/失败）
                                    messageType:'warning',
                                    displayTime:1500
                                })
                            }
                        })
                    }
                } 
                catch (error) 
                {
                    Message.error('系统错误')
                }
            }
        }       
};